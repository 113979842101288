import 'vanilla-cookieconsent/dist/cookieconsent.css';
import 'vanilla-cookieconsent/src/cookieconsent.js';

window.addEventListener('DOMContentLoaded', () => {
  const cc = initCookieConsent();

  const waitForGtag = (callback) => {
    const checkGtag = setInterval(() => {
      if (typeof gtag === 'function') {
        clearInterval(checkGtag);
        callback();
      }
    }, 100);
  };

  const bannerElements = document.getElementsByClassName('cookie-banner');
  for (let i = 0; i < bannerElements.length; i++) {
    const e = bannerElements[i];
    e.style.display = 'flex';
    e.classList.add('show');
  }

  cc.run({
    current_lang: 'fr',
    autoclear_cookies: true,
    page_scripts: true,
    hide_from_bots: true,
    autorun: false,
    cookie_expiration: 365,
    cookie_domain: 'done.fr',

    onAccept: function (cookie) {
      const bannerElements = document.getElementsByClassName('cookie-banner');
      for (let i = 0; i < bannerElements.length; i++) {
        const e = bannerElements[i];
        e.style.display = 'none';
      }

      // Update Google Consent Mode based on user preferences
      waitForGtag(() => {
        const analyticsConsent = cookie.categories.analytics
          ? 'granted'
          : 'denied';
        const adConsent = cookie.categories.ads ? 'granted' : 'denied';

        gtag('consent', 'update', {
          analytics_storage: analyticsConsent,
          ad_storage: adConsent,
          ad_storage: 'granted',
          analytics_storage: 'granted',
          functionality_storage: 'granted',
          personalization_storage: 'granted',
          ad_user_data: 'granted',
          ad_personalization: 'granted'
        });
      });
    },

    onRevoke: function () {
      const bannerElements = document.getElementsByClassName('cookie-banner');
      for (let i = 0; i < bannerElements.length; i++) {
        const e = bannerElements[i];
        e.style.display = 'flex';
      }
    },

    languages: {
      fr: {
        consent_modal: {},
        settings_modal: {
          title: 'Préférences des cookies',
          save_settings_btn: 'Enregistrer les préférences',
          accept_all_btn: 'Tout accepter',
          reject_all_btn: 'Tout refuser',
          close_btn_label: 'Fermer',
          cookie_table_caption: 'Liste des cookies',
          cookie_table_headers: [
            { col1: 'Name' },
            { col2: 'Domain' },
            { col3: 'Expiration' },
            { col4: 'Description' }
          ],
          blocks: [
            {
              title: 'Cookie usage 📢',
              description: `J'utilise des cookies pour assurer les fonctionnalités de base du site Web et pour améliorer votre expérience en ligne. Vous pouvez choisir pour chaque catégorie de vous inscrire ou de vous désinscrire quand vous le souhaitez. Pour plus de détails relatifs aux cookies et d'autres données sensibles, veuillez lire <a href="#" class="cc-link">la politique de confidentialité</a>.`
            },
            {
              title: 'Cookies strictement nécessaires',
              description: `Ces cookies sont indispensables au bon fonctionnement de mon site internet. Sans ces cookies, le site Web ne fonctionnerait pas correctement`,
              toggle: {
                value: 'necessary',
                enabled: true,
                readonly: true
              }
            },
            {
              title: "Cookies de performance et d'analyse",
              description:
                'Ces cookies permettent au site Web de se souvenir des choix que vous avez faits dans le passé',
              toggle: {
                value: 'analytics',
                enabled: false,
                readonly: false
              },
              cookie_table: [
                {
                  col1: '^_ga',
                  col2: 'google.com',
                  col3: '2 ans',
                  col4: 'Google Analytics',
                  is_regex: true
                },
                {
                  col1: '_gid',
                  col2: 'google.com',
                  col3: '1 jour',
                  col4: 'Google Analytics'
                },
                {
                  col1: 'lang',
                  col2: 'linkedin.com',
                  col3: 'session',
                  col4: 'LinkedIn Insight'
                },
                {
                  col1: 'UserMatchHistory',
                  col2: 'linkedin.com',
                  col3: '1 mois',
                  col4: 'LinkedIn Insight'
                },
                {
                  col1: 'BizoID',
                  col2: 'linkedin.com',
                  col3: '1 mois',
                  col4: 'LinkedIn Insight'
                },
                {
                  col1: 'lidc',
                  col2: 'linkedin.com',
                  col3: '1 jour',
                  col4: 'LinkedIn Insight'
                },
                {
                  col1: 'bcookie',
                  col2: 'linkedin.com',
                  col3: '2 ans',
                  col4: 'LinkedIn Insight'
                },
                {
                  col1: 'bscookie',
                  col2: 'linkedin.com',
                  col3: '2 ans',
                  col4: 'LinkedIn Insight'
                },
                {
                  col1: 'ln_or',
                  col2: 'done.fr',
                  col3: '1 jour',
                  col4: 'LinkedIn Analytics'
                }
              ]
            },
            {
              title: "Plus d'informations",
              description: `Pour toute question relative à notre politique en matière de cookies et à vos choix, veuillez
                <a class="cc-link" href="mailto:contact@done.fr">nous contacter</a>.`
            }
          ]
        }
      }
    },
    gui_options: {
      consent_modal: {
        layout: 'bar',
        position: 'bottom center',
        transition: 'slide',
        swap_buttons: false
      },
      settings_modal: {
        layout: 'bar',
        position: 'right',
        transition: 'slide'
      }
    }
  });
});
